import dayjs from 'dayjs';
import { Metric } from '../metrics/types';
import {
  APISchemaDefinition, SchemaMetric, Schema, SchemaSubGroup,
  APISchemaDefinitionMetric,
  SchemaMetricGroup,
} from './types';

function splitMetricFilterGroup(filterGroup: string) {
  const filters = filterGroup.split('//');
  // Filter groups may come in format as 'subGroupName//subGroupFilterLabel//subGroupFilterValue'
  // or 'subGroupName//subGroupFilterValue'.
  const [primaryFilter, secondaryFilter, tertiaryFilter] = filters;
  const subGroupName = primaryFilter ?? null;
  let timePeriod = null;
  if (tertiaryFilter && secondaryFilter.toLowerCase() === 'time period') {
    timePeriod = tertiaryFilter;
  } else if (secondaryFilter) {
    timePeriod = secondaryFilter;
  }
  return { subGroupName, timePeriod };
}

export function transformAPISchemaDefinitionMetricsToSchemaMetrics(
  metrics: APISchemaDefinitionMetric[],
): SchemaMetric[] {
  return metrics.map((metric) => ({
    fin: metric.fin,
    type: metric.type,
    isRequired: metric.isRequired,
    displayName: metric.displayName,
    description: metric.description,
    linkedGroups: metric.linkedGroups,
    timePeriod: splitMetricFilterGroup(metric.filterGroup ?? '').timePeriod,
  }));
}

export function transformSchemaMetricsToAPISchemaDefinitionMetrics({
  metrics,
  subGroupDisplayName,
  enableTimePeriodFilter = false,
  isPinnedMetrics = false,
}: {
  metrics: SchemaMetric[],
  subGroupDisplayName?: string,
  enableTimePeriodFilter?: boolean,
  isPinnedMetrics?: boolean,
}): APISchemaDefinitionMetric[] {
  let primaryFilterGroup: string | null = null;

  if (subGroupDisplayName) {
    primaryFilterGroup = subGroupDisplayName;
  }

  if (isPinnedMetrics) {
    primaryFilterGroup = '';
  }

  return metrics.map((metric) => {
    const timePeriodFilter = enableTimePeriodFilter ? metric.timePeriod : null;
    const filterGroup = primaryFilterGroup && timePeriodFilter ? primaryFilterGroup.concat(`//Time Period//${timePeriodFilter}`) : primaryFilterGroup;
    return {
      fin: metric.fin,
      type: metric.type,
      isRequired: metric.isRequired,
      displayName: metric.displayName,
      description: metric.description,
      linkedGroups: metric.linkedGroups,
      ...filterGroup !== null && { filterGroup },
    };
  });
}

export function transformMetricToSchemaMetric(metric: Metric): SchemaMetric {
  return ({
    fin: metric.fin,
    isRequired: false,
    type: metric.type,
    displayName: metric.name,
    description: metric.description,
    linkedGroups: [],
    timePeriod: null, // TODO: Set to metric.timePeriod when API integrated
  });
}

export function getAllSchemaMetrics(schema: Schema): SchemaMetric[] {
  return schema.groups.map((group) => [
    ...group.generalMetrics,
    ...group.subGroups.map((subGroup) => subGroup.metrics).flat(1),
  ]).flat(1);
}

export function groupMetricsByPrimaryFilter(
  metrics: APISchemaDefinitionMetric[],
): SchemaSubGroup[] {
  const subGroupMetricsByName: { [key: string]: SchemaMetric[] } = {};
  // Group metrics by primary filter
  metrics.forEach((metric) => {
    const subGroupDisplayName = metric.filterGroup ? metric.filterGroup.split('//')[0] : null;
    if (subGroupDisplayName) {
      const transformedMetric = transformAPISchemaDefinitionMetricsToSchemaMetrics([metric])[0];
      subGroupMetricsByName[subGroupDisplayName] = subGroupDisplayName in subGroupMetricsByName
        ? [...subGroupMetricsByName[subGroupDisplayName], transformedMetric] : [transformedMetric];
    }
  });
  return Object.keys(subGroupMetricsByName)
    .map((name) => ({
      displayName: name,
      metrics: subGroupMetricsByName[name],
      enableTimePeriodFilter: metrics.some((metric) => splitMetricFilterGroup(metric.filterGroup ?? '').timePeriod !== null),
    }));
}

export function transformAPISchemaDefinitionToSchema(schema: APISchemaDefinition): Schema {
  return ({
    documentSchemaId: schema.documentSchemaId,
    name: schema.name,
    description: schema.description,
    isTemplate: schema.isTemplate,
    isPublished: schema.isPublished,
    reportingEntityType: schema.reportingEntityType,
    versionedDocumentTypes: schema.versionedDocumentTypes,
    groups: schema.groups.map((group) => ({
      displayName: group.displayName,
      isRepeatingGroup: group.isRepeatingGroup,
      calculatedTotals: group.calculatedTotals,
      generalMetrics: transformAPISchemaDefinitionMetricsToSchemaMetrics(
        group.metrics.filter((metric) => !metric.filterGroup || metric.filterGroup === ''),
      ),
      subGroups: groupMetricsByPrimaryFilter(group.metrics),
    })),
  });
}

export function transformSchemaToAPISchemaDefinition(schema: Schema): APISchemaDefinition {
  const apiSchemaDefinition: APISchemaDefinition = {
    documentSchemaId: schema.documentSchemaId,
    name: schema.name,
    description: schema.description,
    isTemplate: schema.isTemplate,
    isPublished: schema.isPublished,
    reportingEntityType: schema.reportingEntityType,
    versionedDocumentTypes: schema.versionedDocumentTypes,
    groups: [],
  };

  const schemaGroups = schema.groups;
  schemaGroups.forEach((metricGroup: SchemaMetricGroup) => {
    const generalMetrics = transformSchemaMetricsToAPISchemaDefinitionMetrics({
      metrics: metricGroup.generalMetrics,
      isPinnedMetrics: metricGroup.subGroups.length > 0,
    });

    const subGroupMetrics = metricGroup.subGroups.map(
      (subGroup) => transformSchemaMetricsToAPISchemaDefinitionMetrics({
        metrics: subGroup.metrics,
        enableTimePeriodFilter: subGroup.enableTimePeriodFilter,
        subGroupDisplayName: subGroup.displayName,
      }),
    ).flat(1);

    apiSchemaDefinition.groups.push({
      displayName: metricGroup.displayName,
      isRepeatingGroup: metricGroup.isRepeatingGroup,
      calculatedTotals: metricGroup.calculatedTotals,
      metrics: [
        ...generalMetrics,
        ...subGroupMetrics,
      ],
    });
  });
  return apiSchemaDefinition;
}

export const EMPTY_SCHEMA: Schema = {
  documentSchemaId: '',
  name: '',
  description: '',
  isTemplate: false,
  isPublished: false,
  reportingEntityType: '',
  versionedDocumentTypes: [],
  groups: [],
};

export function createNewSchemaName() {
  const now = dayjs();

  const formattedDate = now.format('DD/MM/YYYY');
  const formattedTime = now.format('HH:mm:ss');

  return `Untitled Schema - ${formattedDate} ${formattedTime}`;
}

export function createDraftCopySchemaName(originalSchemaName: string) {
  const now = dayjs();

  const formattedDate = now.format('DD/MM/YYYY');
  const formattedTime = now.format('HH:mm:ss');

  return `Copy of ${originalSchemaName} - ${formattedDate} ${formattedTime}`;
}
