import { logger } from '../logger';
import { getAllSchemaMetrics } from './helpers';
import {
  APISchemaError, Schema, SchemaMetric, SchemaMetricGroup,
} from './types';

export function isMetricGroupDisplayNameUnique(
  groups: SchemaMetricGroup[], metricGroupDisplayName: string,
) {
  const isUnique = !groups.some(
    (group) => group.displayName === metricGroupDisplayName,
  );
  return isUnique;
}

export function isMetricUnique(metricFins: string[], metricFin: string) {
  const isUnique = !metricFins.includes(metricFin);
  return isUnique;
}

export function isAllMetricsUnique(metrics: SchemaMetric[]) {
  const allMetricFins = metrics.map((metric) => metric.fin);
  const isAllUnique = allMetricFins.length === new Set(allMetricFins).size;
  const repeats: any = {};
  allMetricFins.forEach((fin) => {
    repeats[fin] = fin in repeats ? repeats[fin] + 1 : 1;
  });
  return isAllUnique;
}

export function validateAddMetricGroup(schema: Schema, metricGroup: SchemaMetricGroup) {
  const errors = [];

  // Check metric group has a unique display name
  const isNameUnique = isMetricGroupDisplayNameUnique(schema.groups, metricGroup.displayName);
  if (!isNameUnique) {
    errors.push('Metric group name must be unique');
  }

  if (!metricGroup.isRepeatingGroup && metricGroup.calculatedTotals) {
    errors.push('Non-repeating metric groups can not have calculated totals');
  }

  return {
    isValid: errors.length === 0,
    errors,
  };
}

export function validateUpdateMetricGroup(
  schema: Schema,
  currentMetricGroup: SchemaMetricGroup,
  updatedMetricGroup: SchemaMetricGroup,
) {
  const errors = [];

  // If updating name, check updated metric group uses a unique display name
  if (updatedMetricGroup.displayName) {
    const groups = schema.groups.filter(
      (group) => group.displayName !== currentMetricGroup.displayName,
    );
    const isNameUnique = isMetricGroupDisplayNameUnique(groups, updatedMetricGroup.displayName);
    if (!isNameUnique) {
      errors.push('Metric group name must be unique');
    }
  }

  if (!updatedMetricGroup.isRepeatingGroup && updatedMetricGroup.calculatedTotals) {
    errors.push('Non-repeating metric groups can not have calculated totals');
  }

  return {
    isValid: errors.length === 0,
    errors,
  };
}

export function validateUpdateSchemaName(newSchemaName: string) {
  const errors = [];
  if (!newSchemaName || !newSchemaName.length) {
    errors.push('Schema name must be at least 1 character long');
  }

  // TODO: Check if schema name is unique by calling API
  return {
    isValid: errors.length === 0,
    errors,
  };
}

export function validateUpdateSubGroupName(
  metricGroup: SchemaMetricGroup,
  newSubGroupName: string,
) {
  const errors = [];

  const subGroupNames = metricGroup.subGroups.map((subGroup) => subGroup.displayName);
  if (subGroupNames.includes(newSubGroupName)) {
    errors.push(`A view with the name ${newSubGroupName} already exists`);
  }

  return {
    isValid: errors.length === 0,
    errors,
  };
}

export function validateUpdatedSchemaMetrics(schema: Schema) {
  const errors = [];
  const metrics = getAllSchemaMetrics(schema);
  if (!isAllMetricsUnique(metrics)) {
    errors.push('Metrics can not be used in the same schema more than once');
  }

  return {
    isValid: errors.length === 0,
    errors,
  };
}

export function parseAPISaveSchemaError(apiError: APISchemaError | any | APISchemaError[] | null) {
  logger.debug('Parsing API Error: ', apiError);
  let error = apiError;
  if (Array.isArray(error)) {
    // eslint-disable-next-line prefer-destructuring
    error = apiError[0];
  }
  let userFriendlyError = '';
  if (error?.field === 'name' || error?.loc?.includes('name')) {
    let reason = '';
    if (error?.type === 'duplicate_resource_error') {
      reason = 'A schema with this name already exists.';
    }
    userFriendlyError = `Invalid schema name ${reason.length ? `: ${reason}` : ''}`;
  }

  return userFriendlyError;
}
